import React from "react"
import Icon1 from "../../../components/assets/img/pages/landing/new/icon-1.png"
import Icon2 from "../../../components/assets/img/pages/landing/new/icon-2.png"
import Icon3 from "../../../components/assets/img/pages/landing/new/icon-3.png"
import Icon4 from "../../../components/assets/img/pages/landing/new/icon-4.png"
import Icon5 from "../../../components/assets/img/pages/landing/new/icon-5.png"
import Icon6 from "../../../components/assets/img/pages/landing/new/icon-6.png"

const thirdSectionWrapper = [
    {
        IMG: Icon1,
        Title: "Presente certo",
        Text: (
            <p className="blue-font">
                Encontre o presente certo de acordo com as preferências de quem
                você quer agradar. Com Gifthy você acerta sempre!
            </p>
        ),
    },
    {
        IMG: Icon2,
        Title: "Aproveite o seu tempo",
        Text: (
            <p className="blue-font">
                Com apenas alguns cliques você consegue finalizar sua compra. É
                seguro, fácil e rápido! Evite aquelas intermináveis filas de
                pagamento. O Gifthy pensa no seu tempo e sabe o quanto ele é
                valioso para você.
            </p>
        ),
    },
    {
        IMG: Icon3,
        Title: "Em qualquer lugar",
        Text: (
            <p className="blue-font">
                Com Gifthy você faz sua compra de onde você estiver, em casa ou
                viajando em férias ou a trabalho.
                <br />
                Sem preocupação e incômodos.
                <br />E o presente será entregue onde você desejar. Na esquina
                da sua casa ou do outro lado do mundo.
            </p>
        ),
    },
    {
        IMG: Icon4,
        Title: "Sem incômodos",
        Text: (
            <p className="blue-font">
                O Gifthy garante conforto e tranquilidade. Você não quer
                enfrentar lojas lotadas para comprar um presente.
                <br />E os congestionamentos?
                <br />
                Para acertar o presente sem ferver a cabeça, basta contar com o
                auxílio da inteligência digital do Gifthy.
            </p>
        ),
    },
    {
        IMG: Icon5,
        Title: "Garantia de entrega",
        Text: (
            <p className="blue-font">
                Não se preocupe. O presente vai chegar a tempo! O Gifthy conta
                com os melhores fornecedores para garantir que o seu presenteado
                receba o presente rapidamente e em perfeito estado. Você também
                pode receber no seu endereço, para levar pessoalmente na festa.
                Plataformas de logística modernas e ágeis contribuem para a
                melhor experiência possível.
            </p>
        ),
    },
    {
        IMG: Icon6,
        Title: "O melhor preço",
        Text: (
            <p className="blue-font">
                Com o Gifthy você não tem custos extras com tarifas de
                transporte, combustível ou estacionamento. Você paga exatamente
                o valor do presente desejado!
            </p>
        ),
    },
]

const ThirdSection = () => {
    return (
        <section className="fifth" id="por-que">
            <div className="grid">
                {thirdSectionWrapper.map((item, index) => (
                    <div key={index.toString()} className="item">
                        <img src={item.IMG} />
                        <h1 className="blue-font font-bold">{item.Title}</h1>
                        {item.Text}
                    </div>
                ))}
            </div>
        </section>
    )
}

export default ThirdSection
